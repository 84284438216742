import App from 'App'
import 'whatwg-fetch'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { registerSW } from 'virtual:pwa-register'
import './index.css'

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://3a22e7da4002e4f6ae8ad0e421e3ea29@o4504668514156544.ingest.us.sentry.io/4507126237102080',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  tracesSampleRate: 1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/w42-api\.watt42\.com\/api/,
    /^https:\/\/w42-api-test\.watt42\.com\/api/
  ],
  // Session Replay
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  replaysOnErrorSampleRate: 1 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

registerSW()

const MAX_RETRIES = 1
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number.POSITIVE_INFINITY,
      retry: MAX_RETRIES
    }
  }
})

const container = document.querySelector('#root')
if (container) {
  const root = createRoot(container)
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </StrictMode>
  )
}
