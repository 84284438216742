import LoadingOrError from 'components/LoadingOrError'
import type { ReactElement } from 'react'
import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const Index = lazy(async () => import('pages/Index'))
const About = lazy(async () => import('pages/About'))
const Profile = lazy(async () => import('pages/Profile'))
const TestCharts = lazy(async () => import('pages/TestCharts'))
const Gallery = lazy(async () => import('pages/Gallery'))
const Details = lazy(async () => import('pages/Details'))
const SitesIndex = lazy(async () => import('pages/sites/Index'))

export default function App(): ReactElement {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingOrError />}>
        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/about' element={<About />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/sites' element={<SitesIndex />} />
          <Route path='/tests/charts' element={<TestCharts />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path=':fruitName' element={<Details />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
